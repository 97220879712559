import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import { Disqus } from 'gatsby-plugin-disqus';
import Img from 'gatsby-image';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { rhythm, scale } from '../utils/typography';
import { Post, Site, PageContext } from '../types';
import Header from '../components/Header';
import { generateLink } from '../utils/utils';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import './../styles.css';

deckDeckGoHighlightElement();

interface DataProps {
  site: Site;
  markdownRemark: Post;
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        keywords
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const BlogPostPage = (props: PageProps<DataProps, PageContext>): JSX.Element => {
  const { data, pageContext, location } = props;
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  const disqusConfig = {
    url: `${location.href}`,
    identifier: post.id,
    title: post.frontmatter.title,
  };

  return (
    <>
      <Header pageId={'home'} />
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={(post.frontmatter.keywords && post.frontmatter.keywords.join()) || post.excerpt}
          featuredImageUrl={post.frontmatter?.featuredImage?.childImageSharp?.fluid?.src}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <Img style={{ marginBottom: '1.75rem' }} fluid={post.frontmatter?.featuredImage?.childImageSharp?.fluid} />
          <section className="blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={generateLink(previous.frontmatter.date, previous.frontmatter.slug)} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={generateLink(next.frontmatter.date, next.frontmatter.slug)} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>

        <Disqus config={disqusConfig} />
      </Layout>
    </>
  );
};

export default BlogPostPage;
