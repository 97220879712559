export function generateLink(date: string | Date, slug: string): string {
  const blogPostDate = new Date(date);
  const blogPath = `/${[
    addLeadingZeros(blogPostDate.getFullYear(), 4),
    addLeadingZeros(blogPostDate.getMonth() + 1, 2),
    addLeadingZeros(blogPostDate.getDate(), 2),
  ].join('/')}/${slug}`;

  return blogPath;
}
export function addLeadingZeros(num: string | number, leadingZeroCount: number): string {
  const numStr = String(num);
  const numLength = numStr.length;
  const remainingZeroCount = leadingZeroCount - numLength;
  const zeros = new Array(remainingZeroCount).fill(0);
  return `${zeros}${numStr}`;
}
